
.quest-success-overlay {
    z-index: 5;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    color: white;
    pointer-events: none;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.8);
}


.quest-success-container {
    background-image: url(../img/ui/ribbon.svg);
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    padding: 40px 80px;
    animation: spring 0.5s 1;
}

.quest-success-title {
    font-size: 3em;
    margin-bottom: 20px;
    white-space: nowrap;
}

@keyframes spring {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
    
}
