.challenge-container {
    display: flex;
    align-items: stretch;
    width: 100%;
    height: 100%;
    gap: 10px;
}

.challenge-editor {
    flex: 1;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    overflow: hidden;
    flex: 1;
    font-weight: 400 !important;
}

.monaco-editor{
    flex: 1;
    padding: 10px;
    font-weight: 400;
}

.challenge-rules {
    flex: 1;
    padding: 20px;
    background: white;
    border-radius: 10px;
    flex: 2;
    overflow-y: auto;
}

.challenge-output {
    border-radius: 10px;
    padding: 20px;
    background: white;
    flex: 1;
}

.challenge-rules.markdown {
    
}