

.quiz {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.3);
    width:100%;
    max-width: 600px;
    background: white;
}

.question {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 20px;
    color: #45493e;

}

.question-options {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 0;
    padding: 0;
}

.question-option {
    user-select: none;
}

.question-option label {
    color: #545454;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    border: 2px solid rgba(0,0,0,0.05);
    width:100%;
    font-size: 1.2rem;
    cursor: pointer;
}

.question-option.active label {
   border-color: #3498db;
   color: #3498db;
}

.question-option:not(.active) label:hover {
    border: 2px solid rgba(0,0,0,0.1);
}

.question-option span {
    flex: 1;
}

.question-option input {
    transform: scale(1.5);
    accent-color: #3498db;
}

.question button {
    align-self: center;
}