.discussion-ui {
    position: relative;
    display: flex;
    gap: 10px;
    z-index: 2;
    width: 100%;
    max-width: 800px;
    align-items: flex-end;
}

.discussion-avatar {
    position: relative;
    width: 175px;
    height: 200px;
    overflow: hidden;
    object-fit: cover;
}

.discussion-avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.discussion-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 10px;
    flex: 1;
    width: 100%;
    margin-bottom: 10px;
}

.discussion-name {
    background-color: #3498db;
    padding: 10px 20px;
    border-radius: 999px;
    border-bottom-left-radius: 0;
    align-self: flex-start;
    color: white;
}

.discussion-text {
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 10px;
    height: 100px;
    overflow-y: auto;
    border-top-left-radius: 0;
}

.discussion-text p {
    margin: 0;
    line-height: 1em;
    font-size: 18px;
}

.discussion-options {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
}

.discussion-options button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: white;
    color: #222;
    font-size: 18px;
    cursor: pointer;
}

.discussion-options button.main {
    animation-name: yoyo;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    background-color: #3498db;
    color: white;
}

.discussion-options button.danger {
    background-color: #e74c3c;
    color: white;
}

@keyframes yoyo {
    0% { transform: scale(1.0)}
    50% { transform: scale(1.05)}
    100% { transform: scale(1.0)}
}