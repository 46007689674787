.player-hint {
    background-color: white;
    padding: 5px 10px;
    border-radius: 5px;
    margin-top: -250px;
    box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.2);
}

.money-effect {
    color: white;
    font-weight: bold;
    font-size: 150px;
    animation: fade-out 1s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
}

@keyframes fade-out {
    0% {
        opacity: 1;
        transform: scale(1.0);
    }
    100% {
        opacity: 0;
        transform: scale(0.5);
    }
}