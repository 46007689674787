

.computer-gui {
    position: relative;
    background-color: white;
    border-radius: 10px;
    border: solid 25px #111;
    border-top-color: #222;
    border-left-color: #333;
    width: 100%;
    height: 100%;
    flex: 1;
    overflow-y: auto;
  }

  .computer-container {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2;
    background-color: 'white';
    border-radius: '20px';
    padding: 30px;
    width: 100%;
    height: 100%;
  }

  .computer iframe {
    width: 100%;
    height: 100%;
    border: none;
  
  }
/*
  .computer::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(../img/screen-rays.png);
    pointer-events: none;
    opacity: 0.05;
  }

  .computer textarea {
    width: 100%;
    height: 100%;
    background-color: transparent;
    color: white;
    border: none;
    resize: none;
    font-size: 24px;
    padding: 5px;
    outline: none;
    caret: white block;
  }*/