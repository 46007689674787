main.menu {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.menu nav {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-evenly;
    align-items: center;
    gap: 40px;
}

.menu header {
    display: flex;
    align-items: center;
    gap: 20px;
}

.menu header h1 {
    color: white;
    font-size: 5rem;
    margin: 0;
    line-height: 5rem;
}

.menu header h2 {
    color: white;
    font-size: 2rem;
    margin: 0;
    font-weight: 500;
    line-height: 2rem;
    margin-left: 10px;
    opacity: 0.9;
}

.menu .logo {
    width: 150px;
    height: 150px;
    animation: logo 2s ease-in-out 0s infinite;
    animation-direction: alternate;
}

.menu-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.account-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    top: 20px;
    right: 20px;
    padding: 10px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    gap: 5px;
}

.verified-badge {
    width: 20px;
    height: 20px;
    background-color: #1abc9c;
    border-radius: 50%;
    background-image: url(../img/icons/check.svg);
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: center;
    margin-left: 5px;
}

@keyframes logo {
    0% {
        transform: scale(1.0);
    }
    100% {
        transform: scale(1.1);
    }
}

@media screen and (max-width: 768px) {
    .menu header h1 {
        font-size: 2rem;
        line-height: 2rem;
    }

    .menu header h2 {
        font-size: 1rem;
        line-height: 1rem;
    }

    .menu .logo {
        width: 75px;
        height: 75px;
    }

    .menu button {
        padding: 10px 15px;
        font-size: 1.2rem;
    }
    
}