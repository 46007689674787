@import url('./quest-success-overlay.css');
@import url('./player-hints-gui.css');
@import url('./alert.css');
@import url('./markdown.css');
@import url('./sleep-gui.css');

@font-face {
  font-family: Sen;
  src: url(../fonts/Sen/Sen-VariableFont_wght.ttf);
}

@font-face {
  font-family: Roboto;
  src: url(../fonts/Roboto/Roboto-Medium.ttf);
}

* {
    box-sizing: border-box;
    font-family: Roboto;
  }
  
  html,
  body,
  #root {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
  
  body {
    background: #111;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: Sen;
  }
  *.btn {
    background-color: #bdc3c7;
    border-radius: 5px;
    color: #2c3e50;
    border: none;
    cursor: pointer;
    font-weight: 500;
    width: 100%;
    border-bottom-width: 3px;
    border-bottom-style:  solid;
    border-bottom-color: #95a5a6;
    padding: 7px 15px;
    text-align: center;
    text-decoration: none;
    font-size: 0.8rem;
}
*.btn:disabled{
  opacity: 0.5;
  cursor: not-allowed;
}

*.btn:not(:disabled):hover {
  border-bottom-width: 0px !important;
  background-color: #95a5a6;
  margin-top: 3px;
}

*.btn.lg {
  border-radius: 15px;
  font-size: 1.5rem;
  padding: 10px 20px;
  border-bottom-width: 5px;
}

*.btn.lg:not(:disabled):hover{
  border-top-width: 5px !important;
}

*.btn.xl{

  border-radius: 15px;
  font-size: 2rem;
  padding: 15px 40px;
  border-bottom-width: 5px;
}

*.btn.xl:not(:disabled):hover{
  border-top-width: 5px !important;
}

*.btn.primary {
  background-color: #bef92b;
  border-color: #f1c40f;
  color: #43473a;
}

*.btn.primary:not(:disabled):hover {
  border-bottom-width: 0px !important;
  background-color: #b1e728;
}

*.btn.secondary {
  background-color: #1abc9c;
  border-color: #16a085;
  color: white;
}

*.btn.secondary:not(:disabled):hover {
  border-bottom-width: 0px !important;
  background-color: #16a085;
}

*.btn.ternary {
  background-color: #3498db;
  border-color: #2980b9;
  color: white;
}

*.btn.ternary:not(:disabled):hover {
  border-bottom-width: 0px !important;
  background-color: #2980b9;
}

*.btn.outline {
  background: none;
  border: 1px solid #bdc3c7;
  color: #7f8c8d;
  
}

.btn.outline:not(.active):hover {
  background: none;
  margin-top: 0px;
  border-top-width: 1px !important;
  border-bottom-width: 1px !important;
  border: 1px solid #bdc3c7;
  background-color: #ecf0f1;
}

.btn.outline.active {
  color: white;
  background-color:  #34495e;
  border-color: #2c3e50;
  margin-top: 0px;
  border-top-width: 1px !important;
  border-bottom-width: 1px !important;
}

.btn.icon {
  background: none !important;
  border: none !important;
  margin: 0 10px !important;
  width: 30px;
}

.btn.icon img{
  width: 20px;
  height: 20px;
}

*.btn.danger {
  background-color: #e74c3c;
  border-color: #c0392b;
  color: white;
}

*.btn.danger:not(:disabled):hover {
  border-bottom-width: 0px !important;
  background-color: #c0392b;
}

.link {
  text-decoration: none;
  color: #3498db;
}

.progress-bar {
  background-color: rgba(0,0,0,0.2);
  height: 15px;
  border-radius: 1000px;
  overflow: hidden;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
}

.progress-bar.sm {
  height: 10px;
}

.progress-bar.xl {
  height: 20px;
}

.progress {
  position: relative;
  background-color: white;
  height: 100%;
  border-radius: 1000px;
}

.progress::before {
  content: '';
  position: absolute;
  height: 5px;
  left: 7.5px;
  right: 7.5px;
  top: 5px;
  border-radius: 1000px;
  background-color: rgba(0, 0, 0, 0.05);
  opacity: 0.75;
}

.progress-bar.primary {
  background-color: #e5e5e5;
}

.progress-bar.primary .progress {
  background-color: #f3cf49;
}

.progress-bar.primary .progress::before {
  background-color: #bef92b;
}

.progress-bar.sm .progress::before {
  height: 2px;
  top: 2px;
  left: 3px;
  right: 4px;
}

#demo {
  z-index: 1000;
  position: absolute;
  top: 20px;
  left:-55px;
  transform: rotate(-45deg);
  background-color: #e74c3c;
  color: white;
  font-size: 24px;
  font-weight: bold;
  padding: 5px 10px;
  width: 200px;
  display: inline-block;
  text-align: center;
  outline: #e74c3c solid 5px;
  border: #f39c12 dashed 2px;
}

#version {
  color: white;
  font-size: 16px;
}

.label {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 3px;
}
.field {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border: none;
  margin-bottom: 10px;
}