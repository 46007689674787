.map-gui {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 2;
    padding: 40px;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
}

.map-container {
    position: relative;
    border: 40px solid #fff;
    height: 100%;
    background-color: white;
    text-align: center;
}

.map-container img {
    width:100%;
    height:100%;
    object-fit: cover;
    overflow: hidden;
}

.map-point {
    position: absolute;
    background: none;
    border: none;
    color: white;
    text-shadow: 0px 0px 3px rgba(0, 0, 0, 1);
    font-family: Sen;
    font-size: 24px;
    font-weight: bold;
    padding-top: 20px;
    cursor: pointer;
}

.map-point .player-avatar {
    position: absolute;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border-bottom-left-radius: 3px;
    background-color: white;
    border: 4px solid white;
    top: -65px;
    left: 55px;

}

.map-point.current {
    pointer-events: none;
}
.map-point:not(.current){
    animation: pulse 1s infinite;

}

.map-point::before {
    content: '';
    position: absolute;
    top: 0;
    left: calc(50% - 7px);
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: white;
    
}

.map-point::after {
    content: '';
    position: absolute;
    top: -6px;
    left: calc(50% - 13px);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: transparent;
    border: 3px solid white;
    
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}