.login {
    padding: 40px 40px;
    animation: spring 0.5s 1;
    animation-timing-function: ease-in-out;
    border-radius: 0px;
    box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
}

.form-logo {
    width: 75px;
    margin: auto;
}

.form-title {
    font-family: Sen;
    font-weight: 600;
    font-size: 1.8rem;
    text-align: center;
    margin: 10px 0px;
}

.login form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    max-width: 250px;
}

.form-description {
    font-weight: 400;
    font-size: 0.9rem;
    text-align: center;
    margin: 10px 0px;
    opacity: 0.6;
}

@keyframes spring {
    0% {
        transform: scale(1);
    }
    25% {
        transform: scale(1.3);
    }
    50% {
        transform: scale(1);
    }
    75% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
    
}
