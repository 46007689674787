.player-gui {
    position: absolute;
    background-color: #3498db;
    top: 20px;
    left: 100px;
    padding: 5px;
    border-radius: 1000px;
    z-index: 1;
    display: flex;
    align-items: center;
    padding-right: 30px;
    min-width: 250px;
}

.player-avatar {
    width: 65px;
    height:65px;
    margin-right: 10px;
}

.player-info {
    display: flex;
    flex-direction: column;
    padding: 5px 0px;
    width: 100%;
}

.player-info p {
    color: white;
    margin: 0;
    line-height: 1rem;
}

.player-username {
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 5px;
    display: flex;
    align-items: center;
    gap: 10px;
}


.player-level {
    font-size: 14px;
    font-weight: 500;
    background-color: #2980b9;
    border-radius: 3px;
    padding: 3px 5px;
    line-height: 13px;
}

.player-money {
    font-size: 14px;
}

.player-experience {
    display: flex;
    gap: 5px;
    font-size: 14px;
    align-items: center;
    color: white;
}

.player-experience>.progress-bar {
    flex: 1;
}