.quest-gui {
    z-index: 2;
    position: absolute;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    color: white;
    pointer-events: none;
    padding: 20px;
    user-select: none;
}

.quest-container {
    text-align: right;
    max-width: 300px;
    pointer-events: none;
    background: rgba(0,0,0,0.5);
    padding: 15px 20px;
    border-radius: 10px;
}

.quest-title {
    font-size: 1.2em;
    font-weight: bold;
    margin: 0;
}

.quest-container ul {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
}

.quest-task {
    font-size: 1em;
    margin: 0;
}

@media screen and (max-width: 768px) {
    .quest-title {
        font-size: 1em;
    }
    .quest-task {
        font-size: 0.8em;
    }
}