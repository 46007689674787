.letter {
    position: relative;
    padding: 60px 60px;
    animation: spring 0.5s 1;
    animation-timing-function: ease-in-out;
    border-radius: 0px;
    box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.5);
}
@keyframes spring {
    0% {
        transform: scale(1);
    }
    25% {
        transform: scale(1.3);
    }
    50% {
        transform: scale(1);
    }
    75% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
    
}
