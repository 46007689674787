.computer {

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    align-items: center;
    background-color: #f5f5f5;
    padding: 40px;
    background-image: url('/public/img/desktop.jpg');
    background-size: cover;
    background-position: center bottom;
}


.main-window {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-width: 1000px;
    background-color: #ecf0f1;
    border-radius: 10px;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.3);
    overflow: hidden;
}

.window-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    background-color: #bdc3c7;
    padding: 5px 10px;
}

.window-content {
    display: flex;
    align-items: stretch;
    height: 100%;
    gap:10px;
    padding: 10px;
}

.window-content>section {
    flex: 1;
    overflow-y: auto;
    height: 100%;
    padding: 20px;
    background: white;
    border-radius: 5px;
}

.window-content nav {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 78px;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
    padding-top: 20px;
    background-color: white;
    border-radius: 5px;
}

.window-title {
    text-align: center;
    font-size: 32px;
    font-weight: bold;
    margin-top: 0;
    background-image: url(../img/ui/ribbon.svg);
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    color: white;
    padding: 10px 20px;
    padding-top: 15px;
}

.courses-list {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
    margin-top: 20px;
}

.missions-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    margin-top: 20px;
}

.app {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 75px;
    background: none;
    border: none;
    cursor: pointer;
}

.app-icon {
    width: 40px;
    height: 40px;
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 5px;
}

.app-title {
    font-size: 14px;
    font-weight: 400;
    width: 100%;
    text-align: center;
    padding: 2px 4px;
    margin-top: 3px;
    border-radius: 3px;
}

.app.active .app-title {

    background: #f1c40f;
}

.app:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.quiz-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 80px;
}

.quiz-thumbnail-container {
    position: relative;
    display: flex;
    align-items: stretch;
    max-width: 400px;
    width: 100%;
    background: none;
    border: none;
    outline: none;
    gap: 10px;
    margin-bottom: 40px;
    cursor: pointer;
    transition: all 0.3s;
}

.quiz-thumbnail-container:hover {
    transform: scale(1.05);
}

.quiz-thumbnail-container::after{
    content: '';
    display: block;
    position: absolute;
    top: 100%;
    left: 50%;
    width: 0px;
    height: 80px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    z-index: 1;
}

.quiz-thumbnail {
    display: flex;
    flex-direction: column;
    background-color: #f1c40f;
    flex: 1;
    border-radius: 10px;
    border-bottom: 5px solid #f39c12;
    border-right: 5px solid #f39c12;
    justify-content: center;
    align-items: flex-start;
    padding: 15px;
}

.quiz-id {

    background-color: #f1c40f;
    width: 75px;
    height: 75px;
    border-radius: 10px;
    border-bottom: 5px solid #f39c12;
    border-right: 5px solid #f39c12;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
}

.quiz-title {
    font-size: 18px;
    font-weight: bold;

}


.quiz-status {
    position: absolute;
    top: -10px;
    right: -10px;
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #1abc9c;
    background-image: url(../img/icons/check.svg);
    background-size: 20px;  
    background-position: center center;
    background-repeat: no-repeat;
    margin-left: 10px;
}

.quiz-locked {
    position: relative;
    z-index: 2;
    background-color: #ecf0f1;
    padding: 15px;
    border-radius: 50%;
}
.quiz-locked img {
    width: 50px;
    
}

.course-thumbnail {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: stretch;
    position: relative;
    background-color: #1abc9c;
    color: white;
    min-height: 200px;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    border-color: #16a085;
    border-width: 0px;
    border-right-width: 5px;
    border-bottom-width: 5px;
    border-style: solid;
    text-align: left;
    padding: 15px;
    transition: all 0.3s;
    transform: scale(1) rotate(0deg);
}

.course-thumbnail:disabled {
    /*background-color: #bdc3c7;
    border-color: #1abc9c;*/
    opacity: 0.5;
    cursor: not-allowed;

}

.course-thumbnail:not(:disabled):hover {
    transform: scale(1.05) rotate(3deg);
}

.course-thumbnail::before {
    content: '';
    position: absolute;
    top: -4px;
    left: 15px;
    height: 40px;
    width: 40px;
    background-image: url('../img/icons/bookmark-white.svg');
    background-size: contain;
    background-repeat: no-repeat;
}


.course-thumbnail:disabled::after {
    content: '';
    position: absolute;
    top: calc(50% - 40px);
    left: calc(50% - 40px);
    height: 80px;
    width: 80px;
    background-image: url('../img/icons/lock.svg');
    background-size: contain;
    background-repeat: no-repeat;
    transform: rotate(5deg);
}

.course-title {
    font-size: 20px;
    font-weight: bold;
}

.course-difficulty {
    font-style: italic;
}

.course-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
}


.modules-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.module-thumbnail {
    position: relative;
    z-index: 1;
    background-color: #1abc9c;
    color: white;
    padding: 10px 20px;
    padding-left: 10px;
    border-radius: 10px;
    display: flex;
    border: none;
    gap: 10px;
    align-items: center;
    cursor: pointer;
}

.module-status {
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    border: 2px solid white;
}

.module-status.completed::before {
    content: "";
    display: block;
    position: absolute;
    top:3px;
    left: 3px;
    background-image: url('../img/icons/check.svg');
    width: 20px;
    height: 20px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}

.module-info {
    flex: 1;
    display: flex;
}

.module-title {
    font-size: 18px;
    font-weight: medium;
    text-align: left;
    line-height: 18px;
}

.module-content {
    width: 100%;
    max-width: 600px;
    margin: auto;
    padding-bottom: 40px;
}

.mission-thumbnail {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: stretch;
    position: relative;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    border-color: #2980b9;
    border-width: 0px;
    border-right-width: 5px;
    border-bottom-width: 5px;
    border-style: solid;
    text-align: left;
    padding: 15px;
    transition: all 0.3s;
    transform: scale(1) rotate(0deg);
    font-size: 18px;
}

.mission-thumbnail:hover{
    transform: rotate(2deg) scale(1.05);
}

.mission-description {
    margin: 0px;
    margin-top: 5px;
    font-size: 12px;
    font-style: italic ;
}

.mission-category {
    position: absolute;
    z-index: 2;
    bottom: calc(100%);
    left: 15px;
    background-color: #2980b9;
    display: flex;
    align-items: center;
    padding: 3px 10px;
    padding-right: 30px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    gap: 5px;
    font-size: 12px;
    font-weight: 400;
}

.mission-category-icon {
    height: 14px;
}

.mission-difficulty {
    position: absolute;
    z-index: 2;
    margin-top: -5px;
    top: calc(100% + 5px);
    left: 20px;
    background-color: #2980b9;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    gap: 5px;
}

.mission-difficulty i {
    height: 5px;
    width: 20px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 2.5px;
}

.mission-difficulty i.active {
    background-color: white;
}