.shop {
    display: flex;
    flex-direction: column;
    align-items: stretch;    
    position: relative;
    padding: 30px 30px;
    animation: spring 0.5s 1;
    animation-timing-function: ease-in-out;
    border-radius: 0px;
    box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.5);
    z-index: 2;
    background-color: white; 
    max-width: 600px;
    border-radius: 10px;
}
@keyframes spring {
    0% {
        transform: scale(1);
    }
    25% {
        transform: scale(1.3);
    }
    50% {
        transform: scale(1);
    }
    75% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
    
}

.shop-title {
    font-size: 36px;
    font-weight: bold;
    margin: 0;
    text-align: center;
}

.shop-subtitle {
    font-size: 2Opx;
    color: rgba(0,0,0,0.3);
    margin: 0;
    text-align: center;
    font-style: italic;
}

.shop-products {
    display: grid;    
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    margin-top: 40px;

}

.shop-product {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background: rgba(0,0,0,0.1);
    border-radius: 10px;
    box-shadow: inset 0px 0px 10px rgba(0,0,0,0.2);
    padding: 20px;
    border: none;
    height: 150px;
}

.product-icon {
    flex: 1;
    width: 100%;
    background-size: contain;
    margin-bottom: 10px;
    background-repeat: no-repeat;
    background-position: center;
}

.product-name {
    text-align: center;
}

.product-price {
    position: absolute;
    top: 10px;
    right: 10px;
    background: white;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
    border-radius: 999px;
    padding: 2px 10px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.8)
}