.book {
    animation: spring 0.5s 1;
    animation-timing-function: ease-in-out;
    border-radius: 0px;
    box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: stretch;
    border: 20px solid #b71540;
    border-radius: 3px;
    height: 80%;
    aspect-ratio: 13/9;
}
.book-center {
    box-shadow: 0px 0px 20px 5px rgba(0, 0, 0);
    border-right: 1px solid rgba(0, 0, 0, 0.5);
}
.book-page {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 40px 40px;
    gap: 10px;
    align-items: stretch;
}

.book-page h1, .book-page h2, .book-page h3, .book-page h4, .book-page h5, .book-page h6 {
    margin: 0;
    text-align: left;
    line-height: 1em;
}

.book-page h1 {
    margin-bottom: 10px;
    font-size: 2.5em;
}
.book-page p {
    margin: 0;
    text-align: justify;
}

.book-page img {
    align-self: center;
    transform: rotate(4deg);
}
.book-page:nth-child(1){
    border-left: 10px solid rgba(0, 0, 0, 0.5);
}
.book-page:nth-child(3){
    border-right: 10px solid rgba(0, 0, 0, 0.5);
}
@keyframes spring {
    0% {
        transform: scale(1);
    }
    25% {
        transform: scale(1.3);
    }
    50% {
        transform: scale(1);
    }
    75% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
    
}
