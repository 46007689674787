.markdown blockquote {
    position: relative;
    background-color: aqua;
    margin: 0;
    padding: 10px;
    border-radius: 5px;
    border-left: 5px solid #3498db;
}

.markdown code {
    background-color: #ecf0f1;
    padding: 2px 5px;
    border-radius: 5px;
}
.markdown blockquote p {
    margin: 0;
}