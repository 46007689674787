.file, .folder {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px 10px;
    cursor: pointer;
    transition: background-color 0.3s;
    background: none;
    outline: none;
    border: none;
    color: white;

}

.file img, .folder img {
    width: 32px;
    height: 32px;
}